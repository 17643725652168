<template>
  <section class="cont  operate-box">
     <!-- 面包屑 -->
     <el-row class="crumbs-box ">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>运营</el-breadcrumb-item>
           <el-breadcrumb-item>邮件</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="oper-content-box">
        <!-- 查询框 -->
        <el-row class="oper-search-box">
           <el-row class="oper-search-row">
              <el-row class="oper-search-item">
                 <el-button class="bg-gradient" @click="handleAddEdit('add','/create_email')">&nbsp;创建邮件</el-button>
              </el-row>
              <el-row class="oper-search-item">
                 <el-input class="width-200 top-input" placeholder="输入搜索关键词"  clearable
                           v-model="eventName" @keyup.enter.native="getAppletPopup(state)"></el-input>
              </el-row>
           </el-row>
        </el-row>
        <!-- tag-box -->
       <el-row class="tag-box">
         <el-row class="tab-flex">
           <el-row :class="state === 'IN_PROGRESS'? 'active': ''" @click.native="getAppletPopup('IN_PROGRESS')">
             <p class="p1">进行中</p>
             <p class="p2"><span>{{ inProgressCount }}</span>个</p>
           </el-row>
           <el-row :class="state === 'RIGHT_NOW'? 'active': ''" @click.native="getAppletPopup('RIGHT_NOW')">
             <p class="p1">即将开始</p>
             <p class="p2"><span>{{ rightNowCount }}</span>个</p>
           </el-row>
           <el-row :class="state === 'END' ? 'active': ''" @click.native="getAppletPopup('END')">
             <p class="p1">已结束</p>
             <p class="p2"><span>{{ endCount }}</span>个</p>
           </el-row>
           <el-row :class="state === 'DRAFT'? 'active': ''" @click.native="getAppletPopup('DRAFT')">
             <p class="p1">草稿</p>
             <p class="p2"><span>{{ draftCount }}</span>个</p>
           </el-row>
         </el-row>
       </el-row>
        <!--   主体内容   -->
       <el-row class="list">
         <el-row class="card-wrapper" v-for="(item, index) of tableData" :key="index">
           <el-row class="header">
             <el-row class="card-title">{{item.eventName}}</el-row>
             <el-row class="right-opr">
                <ul class="card-ul">
                   <li class="bg-gradient"  @click="handleAddEdit(item,'/sms_campaign')">
                      <i class="el-icon-s-data"></i> <span>活动分析</span>
                   </li>
                   <li class="bg-gradient"  @click="handleAddEdit(item,'/copy-mail','fz')">
                      <i class="el-icon-document-copy"></i> <span>复制活动</span>
                   </li>
                   <li class="bg-gradient" @click="handleAddEdit(item,'/modify-mail')">
                      <i class="el-icon-edit" ></i> <span>编辑活动</span>
                   </li>
                   <li class="bg-gradient" @click="handleDel(item.id)">
                      <i class="el-icon-delete" ></i> <span>删除</span>
                   </li>
                </ul>
             </el-row>
           </el-row>
           <section class="content">
             <el-row class="item">
               <p class="p1">创建时间</p>
               <p class="p2">{{ item.createTime }}</p>
               <p class="p3">目标人群</p>
               <p class="p4">新访问用户</p>
             </el-row>
             <el-row class="item">
               <p class="p1">执行时间</p>
               <p class="p2">{{ item.executeDate }}</p>
             </el-row>
             <el-row class="item">
               <p class="p1">创建人</p>
               <p class="p2">{{ item.createUserName }}</p>
             </el-row>
             <el-row class="item item-user">
               <p class="p1">活动用户数</p>
               <el-row class="cell-box">
                 <el-row class="cell">
                   <el-row class="top-tip">
                     <p>参与用户数
                       <el-tooltip class="m-left-5" effect="dark" content="活动正式下发的去重用户数。在某些情况下，因为用
                                 户缺少相关的属性信息，针对这些缺失用户是不会下发活动的，所以他们不在参与用户数的统计范围内。比如：
                                 Push 活动，缺少设备 token 的用户无法下发，不在参与用户统计之内。" placement="right">
                         <b class="el-icon-question"></b>
                       </el-tooltip>
                     </p>
                     <span>{{ item.joinUserCount }}</span>
                   </el-row>
                   <el-row>
                     <el-progress :format="setFormat" :percentage="item.joinUserCount > 0 ? 100 : 0 "></el-progress>
                   </el-row>
                 </el-row>
                 <el-row class="cell">
                   <el-row class="top-tip">
                     <p>曝光用户数</p><span>{{ item.exposeUserCount }}</span>
                   </el-row>
                   <el-row>
                     <el-progress :percentage="0" :format="setFormat"></el-progress>
                   </el-row>
                 </el-row>
                 <el-row class="cell">
                   <el-row class="top-tip">
                     <p>点击用户数</p><span>{{ item.clickUserCount }}</span>
                   </el-row>
                   <el-row>
                     <el-progress :percentage="0" :format="setFormat"></el-progress>
                   </el-row>
                 </el-row>
                 <el-row class="cell">
                   <el-row class="top-tip">
                     <p>转化用户数</p><span>{{ item.convertUserCount }}</span>
                   </el-row>
                   <el-row>
                     <el-progress :percentage="0" :format="setFormat"></el-progress>
                   </el-row>
                 </el-row>
               </el-row>
             </el-row>
             <el-row class="item item-rate">
               <p class="p1">转化率</p>
               <el-progress class="m-top-15" :width="80" type="circle"
                            :percentage="item.conversionRate">
               </el-progress>
             </el-row>
             <el-row class="item">
               <p class="p1">活动进程</p>
               <el-progress class="m-top-30" :percentage="0"></el-progress>
             </el-row>
             <el-row class="item item-switch" v-show="item.state === 'IN_PROGRESS'">
               <el-row class="action" v-if="item.isStart === 'YES'"
                       @click.native="switchStart(item.id, 'NO')">
                 <i class='iconfont iconzantingtingzhi'></i></el-row>
               <el-row class="action" v-else
                       @click.native="switchStart(item.id, 'YES')">
                 <i class="el-icon-caret-right"></i>
               </el-row>
             </el-row>
           </section>
         </el-row>
       </el-row>
       <!-- 分页 -->
       <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
     </el-row>
  </section>
</template>

<script>
import { urlObj } from '@/api/interface';
import {operate} from "@/api/interface/smtech";
import { mapState } from 'vuex';
export default {
  watch: {
      hotelInfo: {
        handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
              this.hotelId = this.hotelInfo.id
              this.getAppletPopup(this.state)
              this.getStatisticsApplets()
            }
        },
        deep: true
      }
  },
  computed: {
      ...mapState(['hotelInfo'])
  },
  name: 'activityList',
  data(){
    return{
      state: 'IN_PROGRESS',   // 活动状态
      eventName: '',          // 活动名称
      hotelId: '',
      inProgressCount: 0,     // 进行中
      rightNowCount: 0,       // 即将开始
      endCount: 0,            // 已结束
      draftCount: 0,          // 草稿
      tableData: [],
      total: 0,
      page: 1,
      limit: 1,
      // 提示文本
      add_success: "添加成功！",
      del_success: "删除成功！",
      update_success: "修改成功！",
      confirm: '确定',
      cancel: '取消',
      confirm_remove: '确定移除？',
      prompt: '提示！',
      release_success: '发布成功！'
    }
  },
  mounted() {
    this.hotelId = this.hotelInfo.id
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getAppletPopup(this.state)
    this.getStatisticsApplets()
  },
  destroyed() {
    window.removeEventListener('setItem', this.handleFunc)
  },
  methods: {
    // 获取活动状态总数
    getStatisticsApplets(){
      const url = operate.statisticsActivity
      const param = { hotelId: this.hotelId, platform: 'EMAIL' }
      this.$axios.post(url, param).then(res => {
        if (res.success) {
          let data = res.records
          if (res.hasOwnProperty('records')) {
            this.inProgressCount = data.inProgressCount
            this.rightNowCount = data.rightNowCount
            this.endCount = data.endCount
            this.draftCount = data.draftCount
          } else {
            this.inProgressCount = 0
            this.rightNowCount = 0
            this.endCount = 0
            this.draftCount = 0
          }
        }
      })
    },
    // 获取活动列表
    getAppletPopup(state) {
      this.state = state
      const url = operate.operateActivity
      const param = {
        companyId: this.hotelInfo.storeId,
        page: this.page,
        limit: this.limit,
        state: this.state,
        platform: 'EMAIL',
        eventName: this.eventName
      }
      this.$axios.post(url, param).then(res => {
        this.total = res.total
        let tableData = res.records
        tableData.forEach(item => {
          if (item.joinUserCount === 0) {
            item.conversionRate = 0
          }else {
            item.conversionRate = Number(((item.reachUserCount / item.joinUserCount) * 100).toFixed(2))
          }
        })
        this.tableData = tableData
      })
    },
    // 创建/编辑小程序弹窗
    handleAddEdit(item,rou,biaos){
       if (rou != '/create_email'){
          item.eventWechatDTO = JSON.stringify(item.eventWechatDTO)
       }
      this.$router.push({path:rou, query:item,})
    },
    // 删除活动
    handleDel(id){
      this.$confirm(this.confirm_remove, this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: 'warning'
      }).then(() => {
        const param = { id }
        const url = operate.delAppletsPopup
        this.$axios.post(url,param).then(res => {
          if (res.success){
            this.$message({
              showClose: true,
              message: this.del_success,
              type: 'success'
            })
            this.getAppletPopup(this.state)
          }
        })
      })
    },
    // 活动分析
    // handleAnalysis(item){
    //   let { id, eventName, reachUserCount, exposeUserCount,
    //     clickUserCount, joinUserCount, convertUserCount,
    //     eventWechatDTO } = item
    //   let mediaPath = window.getResourcesHost() + eventWechatDTO.mediaPath
    //   let conversionRate = ((reachUserCount / joinUserCount) * 100).toFixed(2)
    //   this.$router.push({path: '/applet_analysis',
    //     query: { id, eventName, joinUserCount, conversionRate,
    //       clickUserCount, convertUserCount, exposeUserCount,
    //       mediaPath }})
    // },
    // 改变每页数
    changePageNum(num){
      this.limit = num
      this.getAppletPopup(this.state)
    },
    // 改变当前页
    changeCurrPage(num){
      this.page = num
      this.getAppletPopup(this.state)
    },
    // 改变活动状态
    switchStart(id, start) {
      const url = urlObj.activeStart
      const param = { id, start }
      this.$axios.post(url, param).then(res => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: '修改状态成功！',
            type: 'success'
          })
          this.getAppletPopup(this.state)
        }
      })
    },
    // 进度条内容
    setFormat(percentage){
      return percentage === ''
    }
  },
}
</script>

<style scoped lang="scss">
//  .top{
//    padding: 20px 0;
//    .top-input { float: right }
//  }
//  .tab{
//    background: #ffffff;
//    .tab-flex {
//      display: flex; padding-left: 40px; font-size: 14px;
//      cursor: pointer;
//      div:hover{ background: #f5f5fa }
//      div{
//        height: 94px; width: 114px; padding-left: 20px;
//        p{ padding-top: 12px; color: #999999 }
//        .p1 { font-weight: 700; }
//        .p2 {
//          span{ font-size: 26px }
//        }
//      }
//      .active p { color: #444444 }
//      .active .p1:before{
//        content: ''; position: absolute; width: 114px;
//        height: 4px; background: #688ff4; top: 0; left: 0;
//      }
//    }
//  }
//  .list{
//    margin-top: 10px; min-width: 1000px;
//    .card-wrapper{
//      padding: 0 20px 10px 20px; box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
//      margin-bottom: 10px; background-color: #ffffff; border-left: 5px solid #ffffff;
//      .header{
//        border-bottom: 1px solid #ececec; padding: 10px 0;
//        overflow: hidden;
//        .title{
//          font-size: 16px; font-weight: bold; line-height: 35px;
//          float: left;
//        }
//        .right-opr{
//          float: right; display: none;
//          .opr-item{
//            width: 28px; height: 28px; border-radius: 50%;
//            background-color: #5ea0ff; color: #ffffff;
//            text-align: center; font-size: 13px; margin-left: 20px;
//            line-height: 21px; cursor: pointer;
//          }
//          .line{ line-height: 28px }
//        }
//      }
//      .content{
//        display: flex;
//        justify-content: space-between;
//        .item{
//          font-size: 12px;
//          min-width: 120px;
//          .p1{ padding: 10px 0; color: #999999 }
//          .p3{ margin-top: 30px; padding: 10px 0; color: #999999 }
//          .m-top-30{ margin-top: 30px }
//        }
//        .item-user{
//          min-width: 200px;
//          .cell-box{ padding-right: 20px }
//          .cell{
//            margin-bottom: 6px;
//            .top-tip{
//              margin-bottom: 4px;
//              p{ float: left }
//              span{ float: right }
//            }
//          }
//        }
//        .item-rate{ min-width: 160px }
//        .item-switch{
//          .action{
//            width: 60px; height: 60px; border: solid 1px #ececec;
//            border-radius: 50px; text-align: center; margin: 50px auto 0;
//            i{ font-size: 40px; line-height: 60px }
//            .iconzantingtingzhi{ font-size: 30px }
//          }
//        }
//      }
//    }
//    .card-wrapper:hover{
//      border-left: 5px solid #678ff4; box-shadow: 0 0 14px #cccccc;
//      .right-opr{ display: flex }
//    }
//  }
//}
@media screen and (max-width: 1366px) {
  .main {
    width: 1500px;
  }
}
</style>
